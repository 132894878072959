export const AboutNavi = [
  {
    label: "면역주사",
    id: 0,
  },
  {
    label: "암성통증치료",
    id: 1,
  },
  {
    label: "온열치료",
    id: 2,
  },
];
