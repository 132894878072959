export const AboutNavi = [
  {
    label: "인사말",
    id: 0,
  },
  {
    label: "시설 안내",
    id: 1,
  },
  {
    label: "프로그램",
    id: 2,
  },
  {
    label: "오시는 길",
    id: 3,
  },
];
