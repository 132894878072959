import { IGridBoxProps } from "../../styles/common";

export const FooterTexts: IGridBoxProps[] = [
  {
    label: "제주선한병원",
    start: 2,
    size: 5,
    row: 2,
    rowsize: 1,
    fontSize: 32,
    fontWeight: 500,
  },
  {
    label: "SITE MENU",
    start: 7,
    size: 5,
    rowsize: 1,
    row: 2,
    fontSize: 32,
    fontWeight: 500,
  },
  {
    label: "제주특별자치도 제주시 중앙로 616",
    start: 2,
    size: 5,
    row: 3,
    rowsize: 1,
    fontSize: 16,
    fontWeight: 400,
    color: "#DBE4E8",
  },
  {
    label: "이용약관",
    start: 7,
    size: 5,
    row: 3,
    rowsize: 1,
    fontSize: 16,
    fontWeight: 400,
    color: "#DBE4E8",
  },
  {
    label: `대표자 : 윤장현\n사업자등록번호 : 747-97-00507\n전화 : 064-722-0054\n팩스 : 064-722-0064\n이메일 : sunhan0054@naver.com\n24시 상담전화 : 010-3964-0054`,
    start: 2,
    size: 5,
    row: 4,
    rowsize: 2,
    fontSize: 16,
    fontWeight: 400,
    color: "#DBE4E8",
  },
  {
    label: "개인정보처리방침",
    row: 4,
    rowsize: 2,
    start: 7,
    size: 5,
    fontSize: 16,
    color: "#DBE4E8",
    fontWeight: 400,
  },
  {
    label: "© 2024 제주선한병원. All rights reserved.",
    row: 6,
    rowsize: 1,
    start: 7,
    size: 5,
    color: "#DBE4E8",
    fontSize: 14,
    fontWeight: 400,
  },
];

export const MobileFooterTexts: IGridBoxProps[] = [
  {
    label: "제주선한병원",
    start: 2,
    size: 5,
    row: 1,
    rowsize: 1,
    fontSize: 20,
    fontWeight: 500,
  },
  {
    label: "제주특별자치도 제주시 중앙로 616",
    start: 2,
    size: 5,
    row: 2,
    rowsize: 1,
    fontSize: 14,
    fontWeight: 400,
    color: "#DBE4E8",
  },
  {
    label: `대표자 : 윤장현\n사업자등록번호 : 747-97-00507\n전화 : 064-722-0054\n팩스 : 064-722-0064\n이메일 : sunhan0054@naver.com`,
    start: 2,
    size: 5,
    row: 3,
    rowsize: 3,
    fontSize: 14,
    fontWeight: 400,
    color: "#DBE4E8",
  },
  {
    label: "SITE MENU",
    start: 7,
    size: 5,
    rowsize: 1,
    row: 7,
    fontSize: 20,
    fontWeight: 500,
  },
  {
    label: "이용약관",
    start: 7,
    size: 5,
    row: 8,
    rowsize: 1,
    fontSize: 14,
    fontWeight: 400,
    color: "#DBE4E8",
  },
  {
    label: "개인정보처리방침",
    row: 9,
    rowsize: 1,
    start: 7,
    size: 5,
    fontSize: 14,
    color: "#DBE4E8",
    fontWeight: 400,
  },
  {
    label: "© 2024 제주선한병원. All rights reserved.",
    row: 12,
    rowsize: 1,
    start: 7,
    size: 5,
    color: "#DBE4E8",
    fontSize: 12,
    fontWeight: 400,
  },
];
