export const AboutNavi = [
  {
    label: "신경계재활",
    id: 0,
  },
  {
    label: "작업치료",
    id: 1,
  },
  {
    label: "통증치료",
    id: 2,
  },
  // {
  //   label: "면역치료",
  //   id: 3,
  // },
];
